import React from 'react';
import PropTypes from 'prop-types';

import { WELCOME_STEP } from './steps';
import snapImage from './images/snap.png';

const WizardWelcome = (props) => {
  const lowercaseVocab = props.challengeVocab.toLowerCase();

  return (
    <div className={`col-8 ${props.currentStep === WELCOME_STEP ? 'd-block' : 'd-none'}`}>
      <div className="row">
        <div className="col-sm-6">
          <h3>Snap {props.challengeVocab}</h3>
          <p>Create your own steps, activity, or
            weight {lowercaseVocab} with our
            Snap {props.challengeVocab} tool!
          </p>

          <p>Whether you&apos;re looking for a custom {lowercaseVocab} for
            yourself, friendly competition one-on-one, or want the motivation and support
            of a group, building your own {lowercaseVocab} is a great
            way to work on your health and have fun with your teammates. Separate from our Group and
            Personal {props.challengesVocab}, our Snap {props.challengesVocab} give you a fun way to
            create your own {lowercaseVocab}.
          </p>

          <p>Simply follow the onscreen instructions to create
            your own Snap {props.challengeVocab}.
          </p>
        </div>

        <div className="col-sm-6 text-center">
          <img className="welcome-image" src={snapImage} alt="Snap Challenge" />
        </div>
      </div>
    </div>
  );
};

export default WizardWelcome;

WizardWelcome.propTypes = {
  currentStep: PropTypes.number.isRequired,
  challengeVocab: PropTypes.string.isRequired,
  challengesVocab: PropTypes.string.isRequired,
};
