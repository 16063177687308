import React from 'react';
import PropTypes from 'prop-types';

import { USERS_STEP } from './steps';
import InviteUsers from './InviteUsers';

const WizardInviteUsers = ({ data, ...rest }) => {
  const handleInputChange = (e) => {
    const usersCopy = data.users.concat(e.target.value);
    const copy = {
      ...data,
      users: usersCopy,
    };
    rest.setData(copy);
  };

  const removeUser = (id) => {
    const usersCopy = data.users.filter(v => v.id !== id);
    const copy = {
      ...data,
      users: usersCopy,
    };
    rest.setData(copy);
  };

  return (
    <div className={`col-8 ${rest.currentStep === USERS_STEP ? 'd-block' : 'd-none'}`}>
      <div>
        <p>
          Invite others to participate in this {rest.challengeVocab.toLowerCase()} with you.
          Simply search by their name or email address in the field below.
        </p>
      </div>

      <InviteUsers
        users={data.users}
        handleInputChange={handleInputChange}
        removeUser={removeUser}
      />
    </div>
  );
};

export default WizardInviteUsers;

WizardInviteUsers.propTypes = {
  currentStep: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  challengeVocab: PropTypes.string.isRequired,
};
