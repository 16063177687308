/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import PropTypes from 'prop-types';

import UserSearch from 'packs/common/UserSearch';

const InviteUsers = (props) => {
  const {
    users,
    handleInputChange,
    removeUser,
    update,
  } = props;

  const statusText = (status) => {
    if (status === undefined) return '--';

    return status;
  };

  const showRemoveButton = (user) => {
    if (user.status === 'accepted') return '';

    return (
      <button
        className="btn-remove"
        type="button"
        onClick={() => { removeUser(user.id); }}
      >
        <i className="fa fa-minus" />
      </button>
    );
  };

  return (
    <div className="invite-users">
      <div>
        <label htmlFor="userSearch">Invite User</label>
        <UserSearch users={users} onChange={handleInputChange} name="userSearch" displaySelectedValues={false} />
      </div>

      <div className="my-5">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              {update && (<th className="text-center">Status</th>)}
              <th className="text-center">Remove User</th>
            </tr>
          </thead>
          <tbody>
            {users.map(v => (
              <tr key={v.id} id={v.id}>
                <td>{v.name}</td>
                {update && (<td className="text-center">{statusText(v.status)}</td>)}
                <td className="text-center">
                  {showRemoveButton(v)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <small className="invite-notice">{users.length} Users Invited</small>
        </div>
      </div>
    </div>
  );
};

InviteUsers.propTypes = {
  users: PropTypes.array,
  handleInputChange: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  update: PropTypes.bool,
};

InviteUsers.defaultProps = {
  users: [],
  update: false,
};

export default InviteUsers;
