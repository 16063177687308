import React from 'react';
import { createRoot } from 'react-dom/client';

import Wizard from 'src/private_challenges/Wizard';
import UpdatePrivateChallenge from 'src/private_challenges/UpdatePrivateChallenge';

document.addEventListener('DOMContentLoaded', () => {
  const wizards = document.querySelectorAll('[data-react="Wizard"]');
  [...wizards].forEach((wizard) => {
    const root = createRoot(wizard);
    const props = JSON.parse(wizard.getAttribute('data-props'));
    root.render(<Wizard {...props} />);
  });

  const updatePrivateChallenges = document.querySelectorAll('[data-react="UpdatePrivateChallenge"]');
  [...updatePrivateChallenges].forEach((updatePrivateChallenge) => {
    const root = createRoot(updatePrivateChallenge);
    const props = JSON.parse(updatePrivateChallenge.getAttribute('data-props'));
    root.render(<UpdatePrivateChallenge {...props} />);
  });
});
