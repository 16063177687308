import React from 'react';
import PropTypes from 'prop-types';

const activeStep = (step, currentStep) => {
  if (currentStep === step) {
    return 'active';
  }
  if (currentStep > step) {
    return 'completed';
  }
  return '';
};

const disableStep = (step, currentStep) => {
  if (currentStep === step) {
    return false;
  }
  return currentStep <= step;
};

const stepperText = (currentStep, titles, setCurrentStep, stepTo) => titles
  .map((value, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={index}>
      <button
        type="button"
        className={activeStep(index, currentStep)}
        onClick={() => { stepTo(index, titles, setCurrentStep); }}
        disabled={disableStep(index, currentStep)}
      >
        {value}
      </button>
    </li>
  ));

const WizardStepper = props => (
  <div className="wizard-stepper">
    <ol>
      {stepperText(props.currentStep, props.titles, props.setCurrentStep, props.stepTo)}
    </ol>
  </div>
);

export default WizardStepper;

WizardStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  titles: PropTypes.array.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  stepTo: PropTypes.func.isRequired,
};
