/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import classNames from 'classnames';

import imagePreview from 'packs/common/image-uploader';
import { validName, validDescription } from './validations';
import ActivityIcon from './icons/ActivityIcon';
import WeightIcon from './icons/WeightIcon';
import StepsIcon from './icons/StepsIcon';
import InviteUsers from './InviteUsers';

const UpdatePrivateChallenge = (props) => {
  const { challenge, update } = props;

  const start = moment(challenge.enrollment.start_date).format('MMMM D, YYYY');
  const end = moment(challenge.enrollment.end_date).format('MMMM D, YYYY');
  const challengeItemType = challenge.challenge_items[0].type;

  const users = challenge.challenge_invites.map((v) => {
    const newObj = { ...v, id: v.user_id };
    return newObj;
  });

  const [data, setData] = useState({
    challengeDetails: {
      challengeName: challenge.name,
      challengeDescription: challenge.description,
      challengeImage: challenge.image,
    },
    users: [...users],
  });

  const [errors, setErrors] = useState([]);
  const [validInputs, setValidInputs] = useState(true);

  const { challengeName } = data.challengeDetails;
  const { challengeDescription } = data.challengeDetails;

  const validateInputs = () => {
    if (validName(challengeName) && validDescription(challengeDescription)) {
      setValidInputs(true);
    } else {
      setValidInputs(false);
    }
  };

  const handleUserInputChange = (e) => {
    const usersCopy = data.users.concat(e.target.value);
    const copy = {
      ...data,
      users: usersCopy,
    };
    setData(copy);
  };

  const removeUser = (id) => {
    const usersCopy = data.users.filter(v => v.id !== id);
    const copy = {
      ...data,
      users: usersCopy,
    };
    setData(copy);
  };

  const handleInputChange = (name, value) => {
    const copy = {
      ...data,
      challengeDetails: {
        ...data.challengeDetails,
        [name]: value,
      },
    };
    setData(copy);
  };

  const handleDelete = () => {
    axios.delete(`/api/v2/challenges/private_challenges/${challenge.id}`)
      .then(() => {
        window.location.href = '/challenges';
      });
  };

  const handleCancel = () => {
    window.location.href = `/challenges/${challenge.id}`;
  };

  const handleSubmit = () => {
    const userIds = data.users.map(user => user.id);
    const { challengeImage } = data.challengeDetails;
    const formData = new FormData();

    formData.append('name', data.challengeDetails.challengeName);
    formData.append('description', data.challengeDetails.challengeDescription);
    userIds.map(userId => formData.append('invites[]', userId));

    if (typeof challengeImage === 'object' && challengeImage !== null) {
      formData.append('image', data.challengeDetails.challengeImage);
    }

    axios.put(`/api/v2/challenges/private_challenges/${challenge.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      window.location.href = `/challenges/${challenge.id}`;
    }).catch((error) => {
      setErrors(error.response.data.errors);
    });
  };

  const activityType = () => {
    switch (challengeItemType) {
      case 'FitnessChallengeItem':
        return 'Activity';
      case 'WeightChallengeItem':
        return 'Weight';
      case 'RoutineChallengeItem':
        return 'Steps';
      default:
        return '';
    }
  };

  const activityTypeIcon = () => {
    switch (challengeItemType) {
      case 'FitnessChallengeItem':
        return (<ActivityIcon />);
      case 'RoutineChallengeItem':
        return (<StepsIcon />);
      default:
        return (<WeightIcon />);
    }
  };

  const backgroundImage = () => data.challengeDetails.challengeImage && { backgroundImage: `url(${data.challengeDetails.challengeImage})` };

  const showErrors = () => errors.length > 0 && (
    <div className="alert alert-danger">
      {errors.map((e, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={key}>{e}</li>
      )) }
    </div>
  );

  useEffect(() => {
    imagePreview();
  });

  useEffect(() => {
    validateInputs();
  }, [challengeName, challengeDescription]);

  return (
    <React.Fragment>
      <div className="page-header d-flex justify-content-between align-items-center">
        <h1>Update a {props.challengeVocab}</h1>
        <button className="btn btn-danger" type="button" onClick={handleDelete}>Delete Challenge</button>
      </div>
      <p>Use the form below to make changes to your Snap {props.challengeVocab}!
        You can even invite additional people to participate.
      </p>

      <div className="card card-clear">
        <div className="card-header">
          <h3>{props.challengeVocab} Details</h3>
        </div>
        <div className="card-body">
          <div className="py-3">
            <h4>{props.challengeVocab} Type</h4>
            <div className="d-flex align-items-center">
              <span className="activity-type-icon me-3">
                {activityTypeIcon()}
              </span>
              {activityType()}
            </div>
          </div>

          <hr />

          <div className="py-3">
            <h4><label htmlFor="challengeName">{props.challengeVocab} Title</label></h4>
            <div className="d-flex align-items-center">
              <input
                type="text"
                placeholder="Limit of 55 characters"
                minLength="4"
                maxLength="55"
                required
                className={classNames('form-control', { 'border border-danger': !validName(challengeName) })}
                value={challengeName}
                name="challengeName"
                id="challengeName"
                onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
              />

            </div>
          </div>

          <hr />

          <div className="py-3">
            <h4><label htmlFor="challengeDescription">{props.challengeVocab} Description</label></h4>
            <div className="d-flex align-items-center">
              <textarea
                required
                className={classNames('form-control', { 'border border-danger': !validDescription(challengeDescription) })}
                name="challengeDescription"
                id="challengeDescription"
                cols="30"
                rows="5"
                value={challengeDescription}
                onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
              />
            </div>
          </div>

          <hr />

          <div className="py-3">
            <h4>Date Range</h4>
            <div className="d-flex align-items-center">
              {start} - {end}
            </div>
          </div>

          <hr />

          <div className="py-3">
            <h4><label htmlFor="image">{props.challengeVocab} Image</label></h4>
            <div className="thumbnail-image-upload js-file-upload col-sm-6" data-img-upload>
              <div
                className="image image-preview"
                style={backgroundImage()}
              />
              <label className="btn btn-danger btn-block btn-upload" data-js-upload-btn htmlFor="challengeImage">
                <i className="fa fa-cloud-upload" /> Select Image
              </label>
              <input
                name="challengeImage"
                id="challengeImage"
                type="file"
                className="form-control d-none"
                onChange={(e) => {
                  handleInputChange(e.currentTarget.name, e.currentTarget.files[0]);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card card-clear">
        <div className="card-header">
          <h3>Invite Users</h3>
        </div>
        <div className="card-body">
          <InviteUsers
            users={data.users}
            handleInputChange={handleUserInputChange}
            removeUser={removeUser}
            update={update}
          />
          <small className="invite-notice">
            * Users who have already accepted your invitation cannot
            be removed from the {props.challengeVocab.toLowerCase()}.
          </small>
        </div>
      </div>

      {showErrors()}

      <button
        type="button"
        className="btn btn-primary me-3"
        disabled={!validInputs}
        onClick={handleSubmit}
        data-test="submitButton"
      >
        Update {props.challengeVocab}
      </button>
      <button type="button" className="btn btn-default" onClick={handleCancel}>Cancel</button>
    </React.Fragment>
  );
};

UpdatePrivateChallenge.propTypes = {
  challengeVocab: PropTypes.string.isRequired,
  challenge: PropTypes.object.isRequired,
  update: PropTypes.bool,
};

UpdatePrivateChallenge.defaultProps = {
  update: false,
};

export default UpdatePrivateChallenge;
