export default function imagePreview() {
  const imgPreview = document.querySelector('[data-img-upload] > .image-preview');
  const input = document.querySelector("[data-img-upload] input[type='file']");

  if (input === null) return;

  input.addEventListener('change', (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      imgPreview.style.backgroundImage = `url(${e.target.result})`;
    };
    reader.readAsDataURL(file);
  });
}
