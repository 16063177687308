/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import 'url-search-params-polyfill';

import RecipientOption from 'src/message/RecipientOption';

const NoOptionsMessage = props => <components.NoOptionsMessage {...props}>No users found</components.NoOptionsMessage>;

const UserSearch = (props) => {
  const [value, setValue] = useState(props.users);

  const generateURL = (input) => {
    const params = new URLSearchParams({ query: input, ...props.additionalParams });
    return `${props.url}?${params.toString()}`;
  };

  const search = input => axios.get(generateURL(input))
    .then(response => response.data.filter((v) => {
      const ids = props.users.map(user => user.id);
      return !ids.includes(v.id);
    }));

  const handleChange = (user) => {
    setValue(user);
    props.onChange({
      target: {
        name: props.name,
        value: user,
      },
    });
  };

  const resetValue = (reset) => {
    if (reset) {
      setValue(null);
      props.setResetSearch(false);
    }
  };

  useEffect(() => {
    resetValue(props.resetSearch);
  }, [props.resetSearch]);

  const formatLabel = option => <RecipientOption {...option} />;

  return (
    <AsyncSelect
      styles={{ menu: base => ({ ...base, zIndex: 3 }) }}
      className="user-search"
      name={props.name}
      onChange={handleChange}
      backspaceRemovesValue
      loadOptions={search}
      value={value}
      components={{ NoOptionsMessage }}
      formatOptionLabel={option => formatLabel(option)}
      controlShouldRenderValue={props.displaySelectedValues}
      getOptionValue={option => option.id}
      placeholder={props.placeholder}
      isClearable={props.isClearable}
    />
  );
};

export default UserSearch;

UserSearch.propTypes = {
  url: PropTypes.string,
  additionalParams: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  displaySelectedValues: PropTypes.bool.isRequired,
  resetSearch: PropTypes.bool,
  setResetSearch: PropTypes.func,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
};

UserSearch.defaultProps = {
  url: '/api/v2/users/search',
  additionalParams: {},
  placeholder: 'Begin typing to find a user...',
  resetSearch: false,
  setResetSearch: null,
  isClearable: false,
};
