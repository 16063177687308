/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import ChallengeCard from 'packs/common/ChallengeCard';
import WizardWelcome from './WizardWelcome';
import WizardChallengeDetails from './WizardChallengeDetails';
import WizardInviteUsers from './WizardInviteUsers';
import WizardReviewChallenge from './WizardReviewChallenge';
import WizardStepper from './WizardStepper';
import * as steps from './steps';

const Wizard = (props) => {
  const [titles] = useState([
    'Welcome',
    `${props.challenge_vocab} Details`,
    'Invite Users',
    `Review ${props.challenge_vocab}`,
  ]);
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({
    challengeDetails: {
      challengeType: { value: '', required: true, dirty: false },
      challengeName: { value: '', required: true, dirty: false },
      challengeDescription: { value: '', required: true, dirty: false },
      challengeStart: { value: '', required: true, dirty: false },
      challengeEnd: { value: '', required: true, dirty: false },
      challengeImage: { value: '', required: false, dirty: false },
    },
    users: [],
  });
  const [stepLocked, setStepLocked] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [unloadListener, setUnloadListener] = useState(false);
  const [errors, setErrors] = useState([]);
  const [hideSpinner, setHideSpinner] = useState(true);

  const start = new Date(data.challengeDetails.challengeStart.value);
  const end = new Date(data.challengeDetails.challengeEnd.value);

  const challengeItemType = (type) => {
    switch (type) {
      case 'activity':
        return 'FitnessChallengeItem';
      case 'steps':
        return 'RoutineChallengeItem';
      case 'weight':
        return 'WeightChallengeItem';
      default:
        return '';
    }
  };

  const forwardButtonText = () => {
    switch (currentStep) {
      case steps.WELCOME_STEP:
        return 'Get Started';
      case steps.REVIEW_STEP:
        return hideSpinner ? `Create ${props.challenge_vocab}` : 'Creating...';
      default:
        return 'Next Step';
    }
  };

  const stepTo = (newStep) => {
    if (newStep >= 0 && newStep < titles.length) {
      setCurrentStep(newStep);
    }
  };

  const confirmCancel = (event) => {
    event.preventDefault();
    event.returnValue = ''; // eslint-disable-line no-param-reassign
  };

  const submit = () => {
    const users = data.users.map(user => user.id);
    const formData = new FormData();

    formData.append('name', data.challengeDetails.challengeName.value);
    formData.append('description', data.challengeDetails.challengeDescription.value);
    formData.append('start_date', data.challengeDetails.challengeStart.value);
    formData.append('end_date', data.challengeDetails.challengeEnd.value);
    formData.append('activity_type', challengeItemType(data.challengeDetails.challengeType.value));
    if (data.challengeDetails.challengeImage.value) {
      formData.append('image', data.challengeDetails.challengeImage.value);
    }
    users.map(user => formData.append('invites[]', user));

    axios.post('/api/v2/challenges/private_challenges', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        window.location.href = `/challenges/${response.data.id}`;
      }).catch((error) => {
        setErrors(error.response.data.errors);
        setHideSpinner(true);
      });
  };

  const showErrors = () => errors.length > 0 && (
    <div className="alert alert-danger">
      {errors.map((e, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={key}>{e}</li>
      )) }
    </div>
  );

  const onNext = (e) => {
    if (currentStep === steps.REVIEW_STEP) {
      setStepLocked(true);
      setUnloadListener(true);
      setHideSpinner(false);
      submit(e);
    } else {
      stepTo(currentStep + 1);
    }
  };

  const readImage = () => {
    const reader = new FileReader();
    const { value: file } = data.challengeDetails.challengeImage;

    reader.onload = (e) => {
      setImgSrc(e.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    readImage();
  }, [data]);

  useEffect(() => {
    window.addEventListener('beforeunload', confirmCancel);
  }, []);

  useEffect(() => () => {
    window.removeEventListener('beforeunload', confirmCancel);
  }, [unloadListener]);

  return (
    <div className="wizard">
      <div className="page-header">
        <h1>{titles[currentStep]}</h1>
      </div>

      <div className="row my-4">
        <WizardWelcome
          currentStep={currentStep}
          challengeVocab={props.challenge_vocab}
          challengesVocab={props.challenges_vocab}
        />
        <WizardChallengeDetails
          currentStep={currentStep}
          data={data}
          setData={setData}
          setStepLocked={setStepLocked}
          challengeVocab={props.challenge_vocab}
        />
        <WizardInviteUsers
          data={data}
          setData={setData}
          currentStep={currentStep}
          challengeVocab={props.challenge_vocab}
        />
        <WizardReviewChallenge
          data={data}
          currentStep={currentStep}
          challengeVocab={props.challenge_vocab}
          setCurrentStep={setCurrentStep}
          imgSrc={imgSrc}
        />

        <div className="col-4">
          <WizardStepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            titles={titles}
            stepTo={stepTo}
          />

          { currentStep === steps.REVIEW_STEP ? (
            <div className="px-3 my-4">
              <h3>Card Preview</h3>
              <hr />
              <div className="px-4">
                <ChallengeCard
                  type={data.challengeDetails.challengeType.value}
                  name={data.challengeDetails.challengeName.value}
                  image_url={imgSrc}
                  start={start}
                  end={end}
                  vocabularized_type={props.vocabularized_type}
                  challenge_vocab={props.challenge_vocab}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="row wizard-footer">
        <div className="col-12 d-flex">
          <button
            type="button"
            onClick={() => { stepTo(currentStep - 1); }}
            className={`btn btn-default ${currentStep === steps.WELCOME_STEP && 'd-none'}`}
            data-test="prevBtn"
          >Previous Step
          </button>
          <button
            type="button"
            onClick={onNext}
            className="btn btn-primary d-flex align-items-center justify-content-around"
            data-test="nextBtn"
            disabled={stepLocked}
          >
            <span className={`spinner-border spinner-border-sm ${hideSpinner && 'd-none'}`} />
            {forwardButtonText()}
          </button>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-8">
          {showErrors()}
        </div>
      </div>
    </div>
  );
};

export default Wizard;

Wizard.propTypes = {
  challenge_vocab: PropTypes.string.isRequired,
  challenges_vocab: PropTypes.string.isRequired,
  vocabularized_type: PropTypes.string.isRequired,
};
