/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import classNames from 'classnames';

import imagePreview from 'packs/common/image-uploader';
import { DETAILS_STEP } from './steps';
import StepsIcon from './icons/StepsIcon';
import ActivityIcon from './icons/ActivityIcon';
import WeightIcon from './icons/WeightIcon';
import {
  validType, validName, validDescription, validDates,
} from './validations';

const WizardChallengeDetails = ({ data, ...rest }) => {
  const QUESTION_COUNT = 5;

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [showButton, setShowButton] = useState(false);

  const { challengeType } = data.challengeDetails;
  const { challengeName } = data.challengeDetails;
  const { challengeDescription } = data.challengeDetails;
  const { challengeStart } = data.challengeDetails;
  const { challengeEnd } = data.challengeDetails;
  const lowercaseVocab = () => rest.challengeVocab.toLowerCase();

  const toggleNextStep = () => {
    const valid = validType(challengeType.value) &&
      validName(challengeName.value) &&
      validDescription(challengeDescription.value) &&
      validDates(challengeStart.value, challengeEnd.value);

    if (!valid && rest.currentStep === DETAILS_STEP) {
      rest.setStepLocked(true);
    } else {
      rest.setStepLocked(false);
    }
  };

  const stepForward = () => {
    if (currentQuestion < QUESTION_COUNT) {
      setCurrentQuestion(currentQuestion + 1);
      setShowButton(false);
    }
  };

  const handleInputChange = (name, value) => {
    const copy = {
      ...data,
      challengeDetails: {
        ...data.challengeDetails,
        [name]: { ...data.challengeDetails[name], value, dirty: true },
      },
    };

    rest.setData(copy);

    if (currentQuestion < QUESTION_COUNT) {
      setShowButton(true);
    }
  };

  const handleDateChange = (start, end) => {
    const copy = {
      ...data,
      challengeDetails: {
        ...data.challengeDetails,
        challengeStart: { value: start, required: true, dirty: true },
        challengeEnd: { value: end, required: true, dirty: true },
      },
    };

    rest.setData(copy);

    if (currentQuestion < QUESTION_COUNT) {
      setShowButton(true);
    }
  };

  const challengeTypeDescriptions = {
    steps: `You have selected a steps ${lowercaseVocab()}. This ${lowercaseVocab()} will allow you
            to record your daily step count and battle it out to be at the top of the leaderboard.`,
    activity: `You have selected an activity ${lowercaseVocab()}. This ${lowercaseVocab()} will allow you
              to record your daily activity minutes and strive to be the most active.`,
    weight: `You have selected a weight ${lowercaseVocab()}. This ${lowercaseVocab()} will allow you
              to record your weekly weight and push to lose the most weight.`,
  };

  const stepOneContent = () => (
    <div>
      <h3>What type of {lowercaseVocab()} would you like to create?</h3>
      <div className="challenge-types my-4">
        <div className="wizard-radio-wrap">
          <input
            id="radio-steps"
            value="steps"
            type="radio"
            name="challengeType"
            checked={challengeType.value === 'steps'}
            onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
          />
          <label htmlFor="radio-steps" className="wizard-radio">
            <StepsIcon />
            <span>Steps</span>
          </label>
        </div>

        <div className="wizard-radio-wrap">
          <input
            id="radio-activity"
            value="activity"
            type="radio"
            name="challengeType"
            checked={challengeType.value === 'activity'}
            onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
          />
          <label htmlFor="radio-activity" className="wizard-radio">
            <ActivityIcon />
            <span>Activity</span>
          </label>
        </div>

        <div className="wizard-radio-wrap">
          <input
            id="radio-weight"
            value="weight"
            type="radio"
            name="challengeType"
            checked={challengeType.value === 'weight'}
            onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
          />
          <label htmlFor="radio-weight" className="wizard-radio">
            <WeightIcon />
            <span>Weight</span>
          </label>
        </div>
      </div>

      <p>{challengeTypeDescriptions[challengeType.value]}</p>
    </div>
  );

  const stepTwoContent = display => (
    <div className={display ? 'd-block' : 'd-none'}>
      <hr className="my-5" />

      <h3>What would you like to name this {lowercaseVocab()}?</h3>
      <p>The name of the {lowercaseVocab()} will be displayed to everyone you invite.</p>

      <input
        type="text"
        placeholder="Limit of 55 characters"
        minLength="4"
        maxLength="55"
        required
        value={challengeName.value}
        onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
        name="challengeName"
        className={classNames('form-control mb-4', { 'border border-danger': !validName(challengeName.value) && challengeName.dirty })}
      />
    </div>
  );

  const stepThreeContent = display => (
    <div className={display ? 'd-block' : 'd-none'}>
      <hr className="my-5" />

      <h3>Please provide a description of the purpose and activity in the {lowercaseVocab()}.</h3>
      <p>The description will be displayed to everyone you invite.</p>

      <textarea
        required
        name="challengeDescription"
        id=""
        cols="30"
        rows="5"
        onChange={(e) => { handleInputChange(e.currentTarget.name, e.currentTarget.value); }}
        value={challengeDescription.value}
        className={classNames('form-control mb-4', { 'border border-danger': !validDescription(challengeDescription.value) && challengeDescription.dirty })}
      />
    </div>
  );

  const stepFourContent = display => (
    <div className={display ? 'd-block' : 'd-none'}>
      <hr className="my-5" />

      <h3 className="challenge-date">On what dates would you like your {lowercaseVocab()} to start and end?</h3>
      <p>{rest.challengeVocab} dates cannot be changed once the setup is complete.</p>

      <Flatpickr
        options={{ mode: 'range', allowInput: true, minDate: moment().format('YYYY-MM-DD') }}
        className={classNames(
          'form-control test-challenge-date mb-4',
          { 'border border-danger': !validDates(challengeStart.value, challengeEnd.value) && (challengeStart.dirty || challengeEnd.dirty) },
        )}
        name="challengeDates"
        onChange={(selectedDates) => {
          const start = moment(selectedDates[0]).format('YYYY-MM-DD');
          const end = moment(selectedDates[1]).format('YYYY-MM-DD');
          handleDateChange(start, end);
        }}
        autoComplete="off"
      />
    </div>
  );

  const stepFiveContent = display => (
    <div className={display ? 'd-block' : 'd-none'}>
      <hr className="my-5" />

      <h3>Choose an image for the {lowercaseVocab()}. (Optional)</h3>
      <p>This image will be displayed to everyone you invite.</p>

      <div className="thumbnail-image-upload js-file-upload" data-img-upload>
        <div className="image image-preview" />
        <label className="btn btn-danger btn-block btn-upload" data-js-upload-btn htmlFor="challengeImage">
          <i className="fa fa-cloud-upload" /> Select Image
        </label>
        <input
          name="challengeImage"
          id="challengeImage"
          type="file"
          className="form-control d-none"
          onChange={(e) => {
            handleInputChange(e.currentTarget.name, e.currentTarget.files[0]);
          }}
        />
      </div>
    </div>
  );

  useEffect(() => {
    imagePreview();
  });

  useEffect(() => {
    toggleNextStep();
  }, [data, rest.currentStep]);

  return (
    <div className={`col-8 ${rest.currentStep === DETAILS_STEP ? 'd-block' : 'd-none'}`}>
      <div className="details-card d-flex justify-content-center">
        <div className="col-9">
          {stepOneContent()}
          {stepTwoContent(currentQuestion >= 2)}
          {stepThreeContent(currentQuestion >= 3)}
          {stepFourContent(currentQuestion >= 4)}
          {stepFiveContent(currentQuestion >= 5)}
          <div>
            <button
              type="button"
              className={`btn btn-primary mb-1 ${showButton ? '' : 'd-none'}`}
              onClick={stepForward}
              data-test="nextQuestionBtn"
            >
              Next Question
            </button>
          </div>

          <p id="step-counter">Step {currentQuestion} of {QUESTION_COUNT}</p>
        </div>
      </div>
    </div>
  );
};

WizardChallengeDetails.propTypes = {
  currentStep: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  setStepLocked: PropTypes.func.isRequired,
  challengeVocab: PropTypes.string.isRequired,
};

export default WizardChallengeDetails;
