import moment from 'moment';

export const validType = challengeType => challengeType.length > 0;

export const validName = challengeName => challengeName.length >= 4 && challengeName.length <= 55;

export const validDescription = challengeDescription => challengeDescription.length > 0;

export const validDates = (challengeStart, challengeEnd) => challengeStart.length > 0 &&
  challengeEnd.length > 0 && moment(challengeStart).isSameOrBefore(challengeEnd);
