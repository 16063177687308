import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const ChallengeCard = (props) => {
  const { start, end } = props;

  return (
    <div className="lh-card">
      <div className="label-begins">
        <i className="fa fa-clock-o" />
        &nbsp; Begins {moment(start).fromNow() }
      </div>

      <div className="lh-card-image" style={{ backgroundImage: `url(${props.image_url})` }} />

      <div className="lh-card-content">
        <h4 className="card-title">
          <small>{props.vocabularized_type}</small>
          {props.name}
        </h4>
        <small className="title">
          {moment(end).diff(start, 'days')} Day {props.challenge_vocab}
        </small>
      </div>

      <div className="lh-card-footer">
        <button type="button" disabled className="btn btn-success btn-sm btn-slant">
          Details <span className="fa fa-angle-right" />
        </button>
        <div className="float-end">
          <button type="button" disabled className="btn btn-info btn-sm btn-slant">
            Join
          </button>
        </div>
      </div>
    </div>
  );
};

ChallengeCard.propTypes = {
  image_url: PropTypes.string,
  name: PropTypes.string.isRequired,
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  challenge_vocab: PropTypes.string.isRequired,
  vocabularized_type: PropTypes.string.isRequired,
};

ChallengeCard.defaultProps = {
  image_url: '',
};

export default ChallengeCard;
