import React from 'react';
import PropTypes from 'prop-types';

const RecipientOption = props => (
  <div className="recipient-option" data-select-option>
    <img src={props.image} width={25} height={25} alt="" />
    <span style={{ marginLeft: '.5em' }}>
      <span>{props.name}</span><span className="ms-2 caption">{props.email}</span>
    </span>
  </div>
);

export default RecipientOption;

RecipientOption.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
};

RecipientOption.defaultProps = {
  email: '',
};
