import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DETAILS_STEP, USERS_STEP, REVIEW_STEP } from './steps';
import ActivityIcon from './icons/ActivityIcon';
import WeightIcon from './icons/WeightIcon';
import StepsIcon from './icons/StepsIcon';

const WizardReviewChallenge = ({ data, ...rest }) => {
  const capitalize = (string) => {
    if (string !== undefined) {
      return string.charAt(0).toUpperCase() + string.substring(1);
    }
    return null;
  };

  const { challengeDetails, users } = data;

  const start = moment(challengeDetails.challengeStart.value).format('MMMM D, YYYY');
  const end = moment(challengeDetails.challengeEnd.value).format('MMMM D, YYYY');

  const activityTypeIcon = () => {
    switch (challengeDetails.challengeType.value) {
      case 'activity':
        return (<ActivityIcon />);
      case 'steps':
        return (<StepsIcon />);
      default:
        return (<WeightIcon />);
    }
  };

  return (
    <div className={`col-8 ${rest.currentStep === REVIEW_STEP ? 'd-block' : 'd-none'}`}>
      <div className="card card-clear">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3>{rest.challengeVocab} Details</h3>
          <button
            type="button"
            className="btn btn-clear"
            onClick={() => { rest.setCurrentStep(DETAILS_STEP); }}
          >
            Edit
          </button>
        </div>
        <div className="card-body">
          <div className="py-3">
            <h4>{rest.challengeVocab} Type</h4>
            <div className="d-flex align-items-center">
              <span className="activity-type-icon me-3">
                {activityTypeIcon()}
              </span>
              {capitalize(challengeDetails.challengeType.value)}
            </div>
          </div>

          <hr />

          <div className="py-3">
            <h4>{rest.challengeVocab} Name</h4>
            {challengeDetails.challengeName.value}
          </div>

          <hr />

          <div className="py-3">
            <h4>{rest.challengeVocab} Description</h4>
            <div className="ws-prewrap">{challengeDetails.challengeDescription.value}</div>
          </div>

          <hr />

          <div className="py-3">
            <h4>Date Range</h4>
            {start} - {end}
          </div>

          <hr />

          <div className="py-3">
            <h4>{rest.challengeVocab} Image</h4>
            <div className="col-4 px-0">
              <img className="img-fluid" src={rest.imgSrc} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="card card-clear">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3>Invite Users</h3>
          <button
            type="button"
            className="btn btn-clear"
            onClick={() => { rest.setCurrentStep(USERS_STEP); }}
          >
            Edit
          </button>
        </div>
        <div className="card-body">
          {users.map(v => (
            <div className="my-3" key={v.id}>{v.name}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WizardReviewChallenge;

WizardReviewChallenge.propTypes = {
  currentStep: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  challengeVocab: PropTypes.string.isRequired,
};
